/// <reference path="references.ts"/>
var tooltip;
var filters = new Array();
var calendar;
calendar = {
    init: function () {
        calendar.attachFullCalendar();
        calendar.showCalendar();
        calendar.loadEventsFromHTML();
    },
    eventList: [],
    attachFullCalendar: function () {
        if (!$('#fullcalendar').length) {
            return false;
        }
        calendar.attachToolTip();
        $('#fullcalendar').fullCalendar({
            editable: false,
            eventLimit: true,
            header: {
                left: '',
                center: '',
                right: '',
            },
            eventMouseover: function (data, event, view) {
                var price = '';
                var date_text = moment(data.start, 'YYYY-MM-DD').format('DD MMMM YYYY');
                if (data.end) {
                    date_text += ' to ' + moment(data.end, 'YYYY-MM-DD').format('DD MMMM YYYY');
                }
                var content = '<a class="toggleEvents" data-target="' + data.id + '"><h3>' + data.title + '</h3></a>' +
                    '<div class="event-row">' +
                    '<span class="event-title">Date:</span><span class="event-info">' + date_text + '</span>' +
                    '</div>' +
                    '<div class="event-row">' +
                    '<span class="event-title">RSVP:</span><span class="event-info"><a class="event-button" href="/calendar/rsvp?event_name=' + data.title + '">RSVP</a></span>' +
                    '</div>';
                tooltip.set({
                    'content.text': content,
                    'style.classes': data.type,
                    'position.target': $(this)
                })
                    .reposition(event).show(event);
            },
            events: "calendar/json",
            eventRender: function (event, element) {
                if (filters.length) {
                    if ($.inArray(event.type, filters) != -1) {
                        $(element).show();
                    }
                    else {
                        $(element).hide();
                    }
                }
            },
        });
        calendar.filterCalendar(filters, calendar.getCurrentMonth());
        //next and previous click events
        $('.fc-button-next, .fc-button-prev').on('click', function () {
            calendar.filterCalendar(filters, calendar.getCurrentMonth());
        });
        calendar.attachCalendarFilter();
        calendar.initUserInterface();
        calendar.toolbarOptionHandler();
    },
    initUserInterface: function () {
        //Set inital calendar date
        var current_date = moment($('#fullcalendar').fullCalendar('getDate')).format('MMMM YYYY');
        $('#acsep_calendar_current-date').html(current_date);
        //Detect previous/next button clicks
        $('.calendar__toolbar--previous-button').on('click', function () {
            $('#fullcalendar').fullCalendar('prev');
            var current_date = moment($('#fullcalendar').fullCalendar('getDate')).format('MMMM YYYY');
            $('#acsep_calendar_current-date').html(current_date);
            calendar.filterCalendar(filters, calendar.getCurrentMonth());
        });
        $('.calendar__toolbar--next-button').on('click', function () {
            $('#fullcalendar').fullCalendar('next');
            var current_date = moment($('#fullcalendar').fullCalendar('getDate')).format('MMMM YYYY');
            $('#acsep_calendar_current-date').html(current_date);
            calendar.filterCalendar(filters, calendar.getCurrentMonth());
        });
    },
    //Loading events from html and keep them in an array for filtering.
    loadEventsFromHTML: function () {
        var events = $('.ui-accordion-header--title');
        var event_details = [];
        for (var i = 0; i < events.length; i++) {
            event_details = {
                'id': $(events[i]).attr('data-id'),
                'start_month': $(events[i]).attr('data-start-month'),
                'start_year': $(events[i]).attr('data-start-year'),
                'end_month': $(events[i]).attr('data-end-month'),
                'end_year': $(events[i]).attr('data-end-year'),
                'type': $(events[i]).attr('data-type')
            };
            calendar.eventList.push(event_details);
        }
        //Do initial filtering on pageload.
        calendar.filterList(filters, calendar.getCurrentMonth());
    },
    toolbarOptionHandler: function () {
        //Detect list view and grid view button clicks and handle them.
        $('#calendar__toolbar--list-icon').on('click', function () {
            //Hide grid view and show list view
            $('.calendar__grid-view').addClass('is-hidden');
            $('.calendar__list-view').removeClass('is-hidden');
            $('.grid-icon').removeClass('grid-icon--active');
            $('.list-icon').addClass('list-icon--active');
            calendar.filterCalendar(filters, calendar.getCurrentMonth());
        });
        $('#calendar__toolbar--grid-icon').on('click', function () {
            //Hide list view and show grid view
            $('.calendar__list-view').addClass('is-hidden');
            $('.calendar__grid-view').removeClass('is-hidden');
            $('.list-icon').removeClass('list-icon--active');
            $('.grid-icon').addClass('grid-icon--active');
        });
    },
    attachToolTip: function () {
        tooltip = $('<div/>').qtip({
            id: 'fullcalendar',
            prerender: true,
            content: {
                text: ' ',
                title: {
                    button: true
                }
            },
            position: {
                my: 'bottom center',
                at: 'top right',
                viewport: $(window),
                container: $('#fullcalendar'),
                adjust: {
                    mouse: true,
                    scroll: false
                }
            },
            events: {
                show: function (event, api) {
                    $('.toggleEvents').click(function () {
                        var eventId = $(this).data('target');
                        $('.listing-news').find('[data-id=' + eventId + ']').trigger('click');
                    });
                }
            },
            style: 'qtip-light'
        }).qtip('api');
    },
    attachCalendarFilter: function () {
        //add all the filters first
        $('#fullcalendar-filter input').each(function (index, item) {
            if ($(item).is(":checked")) {
                filters.push($(item).val());
            }
        });
        calendar.filterCalendar(filters, calendar.getCurrentMonth());
        $('#fullcalendar-filter input').on('click', function () {
            if ($(this).is(':checked') && $.inArray($(this).val(), filters)) {
                filters.push($(this).val());
            }
            else {
                //remove the unchecked item from the array
                var index = filters.indexOf($(this).val());
                if (index != -1) {
                    filters.splice(index, 1);
                }
            }
            calendar.filterCalendar(filters, calendar.getCurrentMonth());
            calendar.filterList(filters, calendar.getCurrentMonth());
        });
    },
    filterList: function (types, month) {
        var current_year = calendar.getCalendarCurrentYear();
        var event_item_count = 0;
        var first_event_item_id = 0;
        //Lets do our magic and filter things out from the list.
        for (var i = 0; i < calendar.eventList.length; i++) {
            var months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
            var match_with_the_types = false;
            var match_with_the_month = false;
            var match_with_the_year = false;
            var show_on_the_list = false;
            var event_start = new Date(calendar.eventList[i].start_year, calendar.eventList[i].start_month, 1);
            var event_start_month = event_start.getMonth();
            var event_start_year = event_start.getFullYear();
            var event_start_date_number = event_start.getFullYear() + '' + event_start.getMonth();
            var event_end = new Date(calendar.eventList[i].end_year, calendar.eventList[i].end_month, 1);
            var event_end_month = event_end.getMonth();
            var event_end_year = event_end.getFullYear();
            var event_end_date_number = event_end.getFullYear() + '' + event_end.getMonth();
            var current_date_number = current_year + '' + moment($('#fullcalendar').fullCalendar('getDate')).format('M');
            var current_month = moment($('#fullcalendar').fullCalendar('getDate')).format('MM');
            //First check would be to verify, the event falls within the selected event types.
            for (var x = 0; x < types.length; x++) {
                if (calendar.eventList[i].type == types[x]) {
                    match_with_the_types = true;
                }
            }
            //Check if event would continue for more than a day.
            if (event_start_date_number != event_end_date_number) {
                //Check if we are in between start and end months
                if (current_month >= event_start_month && current_month <= event_end_month) {
                    match_with_the_month = true;
                }
                //Check if we are in between start and end years
                if (current_year >= event_start_year && current_year <= event_end_year) {
                    match_with_the_year = true;
                }
            }
            else {
                //If an event won't continue for more than a day then we assume
                //it's a one day event and show only in the events start month.
                var event_month = 0;
                if (calendar.eventList[i].start_month < 10) {
                    event_month = months[calendar.eventList[i].start_month.replace('0', '') - 1];
                }
                else {
                    event_month = months[calendar.eventList[i].start_month - 1];
                }
                //Secondly check if the month matches
                if (event_month.toLowerCase() == month) {
                    match_with_the_month = true;
                }
                //Lastly check if the year matches
                if (calendar.eventList[i].start_year == current_year) {
                    match_with_the_year = true;
                }
            }
            //Lets reset everything before removing items from the list view.
            $('#' + calendar.eventList[i].id + '--head').removeClass('is-hidden');
            $('#' + calendar.eventList[i].id + '--content').removeClass('is-hidden');
            $('.calendar__list-view--no-events-found').addClass('is-hidden');
            if (match_with_the_types == true && match_with_the_month == true && match_with_the_year == true) {
                show_on_the_list = true;
                //Using this count to expand the visible item of the accordion.
                event_item_count++;
                //Expan the first item on the list
                if (event_item_count == 1) {
                    $('#' + calendar.eventList[i].id + '--head').click();
                }
            }
            //Show no events found message if there are no events
            if (event_item_count == 0) {
                $('.calendar__list-view--no-events-found').removeClass('is-hidden');
            }
            //Hiding events that shouldn't be on the list view after filtering.
            if (!show_on_the_list) {
                $('#' + calendar.eventList[i].id + '--head').addClass('is-hidden');
                $('#' + calendar.eventList[i].id + '--content').addClass('is-hidden');
            }
        }
    },
    filterCalendar: function (types, month) {
        var currentYear = calendar.getCalendarCurrentYear();
        $('#events-listing li').hide();
        //many types, one month
        if (types.length && month) {
            var has_event = false;
            //if the type and month matches, show the event, else show the none event
            $.each(types, function (index, item) {
                $('#events-listing .' + item + '.' + month + '.' + currentYear).show();
                if ($('#events-listing .' + item + '.' + month + '.' + currentYear).length) {
                    has_event = true;
                }
            });
            if (!has_event) {
                $('#events-listing .none').show();
            }
        }
        else if (month) {
            if ($('#events-listing .' + month + '.' + currentYear).length) {
                $('#events-listing .' + month + '.' + currentYear).show();
            }
            else {
                $('#events-listing .none').show();
            }
        }
        if ($(window).outerWidth() > 460) {
            $('#fullcalendar').fullCalendar('rerenderEvents');
        }
        //Filtering the events on list view.
        calendar.filterList(filters, calendar.getCurrentMonth());
    },
    getCurrentMonth: function () {
        //use fullcalendar dateformat to get the month
        var cal_month = moment($('#fullcalendar').fullCalendar('getDate')).format('MMM');
        // cal_month = $.fullCalendar.formatDate( cal_month, 'MMM');
        return cal_month.toLowerCase();
    },
    getCalendarCurrentYear: function () {
        //use fullcalendar dateformat to get the month
        var cal_year = moment($('#fullcalendar').fullCalendar('getDate')).format('YYYY');
        // cal_year = $.fullCalendar.formatDate( cal_year, 'YYYY');
        return cal_year.toLowerCase();
    },
    showCalendar: function () {
        if ($('#show_calendar').length) {
            $('#show_calendar').click(function () {
                $('.listing-news__item.active').removeClass('active');
                $('.content__main-inner').empty();
                $('#calendar_wrapper').show();
                $(this).hide();
            });
        }
    },
};
