/// <reference path="references.ts"/>
var tooltip;
var filters = new Array();
var main = {
    init: function () {
        main.initMultipleFileDetection();
        main.initMobileNav();
        main.initSearch();
        main.initVideoHero();
        main.initCarousels();
        main.initCarouselListeners();
        main.initInputValueDetection();
        main.initCpdProgressCircle();
        main.intoCpdRecordTable();
        main.attachShareThis();
        main.initAnchorLinks();
        main.initCustomScrollbar();
        main.initPicker();
        main.initIcmsPaymentForm();
        main.initMemberClinicSaveAsBtn();
        NewsListing.init();
        FindAPhysician.init();
        calendar.init();
        main.initHappeningCarousel();
        main.attachChangeEventForCountry();
        main.validateCardNumber();
    },
    /**
     * Validate credit card number on payment and invoice
     * payment page.
     */
    validateCardNumber: function () {
        var ccInput = $('#cc_number');
        if (ccInput.length <= 0) {
            return;
        }
        // Format autofilled value
        var cleanedNumber = main.formatCardNumber(ccInput.val());
        ccInput.val(cleanedNumber);
        ccInput.on('focusout', function () {
            var cleanedNumber = main.formatCardNumber($(this).val());
            $(this).val(cleanedNumber);
        });
    },
    /**
     * Remove spaces and hyphens from card number.
     */
    formatCardNumber: function (ccNumber) {
        var value = ccNumber;
        var formattedValue = value.replace(/\s/g, '');
        formattedValue = formattedValue.replace(/-/g, '');
        return formattedValue;
    },
    initMultipleFileDetection: function () {
        $('.cpd-summary__journal-form-input--file').change(function () {
            var selected_files = $(this)[0].files;
            var selected_files_html = "";
            for (var i = 0; i < selected_files.length; i++) {
                selected_files_html = selected_files_html + selected_files[i].name + "<br>";
            }
            $('.cpd-summary__journal-form-container-file-list').html(selected_files_html);
        });
    },
    initMemberClinicSaveAsBtn: function () {
        if (!$('.member-profile__form-save-as-new').length) {
            return false;
        }
        $('.member-profile__form-save-as-new').click(function (e) {
            // Blank out the practice id
            $(this).siblings('input[name="practice_id"]').val('');
        });
    },
    initIcmsPaymentForm: function () {
        $('input[type="hidden"][name="invoice_no"]').each(function () {
            // The invoice number will be made up of .... <form_id>-<Unique Timestamp><Random suffix>
            var form_id = 1309; //just hardcode for simplicity
            var random_suffix = Math.floor((Math.random() * 36)).toString(36).toUpperCase();
            var invoice_no = form_id + '-' + Date.now().toString(36).toUpperCase() + random_suffix;
            console.log(invoice_no);
            $(this).val(invoice_no);
        });
    },
    initPicker: function () {
        if (!$('.js-picker-date').length) {
            return false;
        }
        $('.js-picker-date').pickadate({
            format: 'dd/mm/yyyy',
            formatSubmit: 'dd/mm/yyyy'
        });
    },
    initCustomScrollbar: function () {
        if (!$('.js-custom-scroll').length) {
            return false;
        }
        $('.js-custom-scroll').mCustomScrollbar();
    },
    initAnchorLinks: function () {
        $('a.scroll-to[href*=\\#]:not([href=\\#])').click(function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') || location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    main.scrollToElement(target, 0);
                }
            }
            return false;
        });
    },
    expandCpdSummary: function (record_btn) {
        if (!$(record_btn).length) {
            return false;
        }
        // In-active all other active record button
        $('.cpd-summary__record.active').removeClass('active');
        $(record_btn).addClass('active');
        var target = $(record_btn).data('target');
        $('.cpd-summary__journal').hide();
        main.resetCpdForm();
        $(target).fadeIn('fast', function () {
            main.scrollToElement(target, 0);
        });
    },
    intoCpdRecordTable: function () {
        if (!$('.cpd-summary__record').length) {
            return false;
        }
        if (!$('.cpd-summary__journal').length) {
            return false;
        }
        // Show the table of activitys when the record activity button is clicked
        $('.cpd-summary__record').each(function () {
            var record_btn = this;
            $(record_btn).click(function (e) {
                e.preventDefault();
                main.expandCpdSummary(record_btn);
            });
            $(record_btn).closest('.cpd-summary__category').find('.cpd-summary__category-progress').click(function (e) {
                e.preventDefault();
                main.expandCpdSummary(record_btn);
            });
        });
        // Open record form on click of the record button on each table row
        $('.cpd-summary__journal-open-form').click(function (e) {
            e.preventDefault();
            // Get basic variables
            var target_row = $(this).closest('tr');
            var target_row_height = target_row.height();
            var record_form = target_row.closest('.cpd-summary__journal-container').find('.cpd-summary__journal-form');
            var opened_row = $(this).closest('.cpd-summary__journal').find('.recording').first();
            var activity_id = $(this).data('activity');
            var activity_use_hours = $(this).data('use-hours');
            var activity_use_complete = $(this).data('use-complete');
            var previous_logs = $(this).data('logs');
            var is_special_high_level_requirement = target_row.hasClass('is_shlr');
            console.log(activity_id);
            // 121 - Other Specialist High-Level Requirement (Optional) module
            if (is_special_high_level_requirement && activity_id == 121) {
                $('.journal-specialty').removeClass('is-hidden');
            }
            else {
                $('.journal-specialty').addClass('is-hidden');
            }
            // Close opened rows and form
            main.resetCpdForm();
            // Show hide hour / completed checkbox
            if (!activity_use_hours) {
                record_form.find('.cpd-summary__journal-form-container.hour').hide();
                record_form.find('.cpd-summary__journal-form-container.minute').hide();
            }
            if (!activity_use_complete) {
                record_form.find('.cpd-summary__journal-form-container.complete').hide();
            }
            // Set the activity id of the form
            record_form.find('input[name="activity_id"]').val(activity_id);
            // Populate the previous logs table if we have the data, else hide the section
            record_form.find('.cpd-summary__journal-table').hide();
            if (previous_logs != '') {
                // Show the previous log table and clear the content
                record_form.find('.cpd-summary__journal-table').show();
                var table_data = record_form.find('.cpd-summary__journal-table-data');
                table_data.html('');
                // Populate the previous log data area
                for (var i = 0; i < previous_logs.length; i++) {
                    var prev_log = previous_logs[i];
                    // console.log(prev_log);
                    var log_json = JSON.stringify(prev_log);
                    log_json = log_json.replace(/(["])/g, "&quot;");
                    var update_btn = '<span class="cpd-summary__journal-old-update" data-log="' + log_json + '">Update</span>';
                    var del_btn = '<span class="cpd-summary__journal-old-del" data-id="' + prev_log.id + '">Delete</span>';
                    var data_section = '<div class="cpd-summary__journal-item">' + update_btn + del_btn + '<span class="date-logname">' + prev_log.date_display + ' - ' + prev_log.title + '</span>';
                    if (prev_log.document != "" && prev_log.document != null) {
                        data_section += '<a href="' + prev_log.document + '" target = "_blank" class="doc_icon"><span class="cpd-summary__journal-icon"></span></div>';
                    }
                    else {
                        data_section += '</div>';
                    }
                    table_data.prepend(data_section);
                }
                // Attach click event to the previous log's update button
                table_data.find('.cpd-summary__journal-old-update').click(function (e) {
                    e.preventDefault();
                    main.resetCpdRecordForm();
                    main.loadPrevLogForm(this, record_form);
                });
                // Attach click event to the previous log's delete button
                table_data.find('.cpd-summary__journal-old-del').click(function (e) {
                    e.preventDefault();
                    var row = $(this).closest('.cpd-summary__journal-item');
                    var id = $(this).data('id');
                    $.ajax({
                        url: '/member/log/delete/' + id
                    })
                        .done(function (data) {
                        // Remove row from DOM
                        row.remove();
                    });
                });
            }
            // Open the target row
            target_row.addClass('recording');
            target_row.find('td').css('padding-bottom', record_form.height() + 30);
            $(this).hide();
            // Show the form and scroll to it after a show delay
            setTimeout(function () {
                record_form.css('top', target_row.position().top + target_row_height);
                record_form.fadeIn('300ms');
                main.scrollToElement(target_row, 0);
            }, 300);
        });
        // Close the opened form when cancel button is clicked
        $('.cpd-summary__journal-form-close').click(function (e) {
            e.preventDefault();
            main.resetCpdForm();
        });
    },
    loadPrevLogForm: function (button, record_form) {
        // Populate the form with value if a log for the activity exists
        var log = $(button).data('log');
        if (!log || !log.has_log) {
            return false;
        }
        record_form.find('.form-heading').html('Update log - ' + log.title);
        record_form.find('input[name="log_id"]').val(log.id);
        record_form.find('input[name="title"]').val(log.title);
        record_form.find('input[name="date"]').val(log.date);
        record_form.find('textarea[name="description"]').val(log.description);
        record_form.find('input[name="specialty"]').val(log.specialty);
        record_form.find('input[name="hours"]').val(log.hours);
        record_form.find('select[name="minutes"]').val(log.minutes);
        if (log.completed) {
            record_form.find('input[name="completed"]').prop('checked', true);
        }
        record_form.find('input, textarea').addClass('has-input');
        if (log.document) {
            record_form.find('.cpd-summary__journal-form-file-link').show();
            record_form.find('.cpd-summary__journal-form-file-link').attr('href', log.document);
            record_form.find('.cpd-summary__journal-form-label--file').html('Re-upload record');
        }
        main.scrollToElement(record_form, 0);
    },
    resetCpdForm: function () {
        if (!$('.cpd-summary__journal-form').length) {
            return false;
        }
        main.closeCpdActivityRow();
        main.resetCpdRecordForm();
    },
    closeCpdActivityRow: function () {
        var opened_row = $('.cpd-summary__journal-table tr.recording');
        var record_form = $('.cpd-summary__journal-form');
        if (opened_row.length) {
            opened_row.removeClass('recording');
            opened_row.find('td').css('padding-bottom', '');
            opened_row.find('.cpd-summary__journal-open-form').show();
        }
        record_form.hide();
    },
    resetCpdRecordForm: function () {
        var record_form = $('.cpd-summary__journal-form');
        if (!record_form.length) {
            return false;
        }
        record_form[0].reset();
        record_form.find('.form-heading').html('Record new activity log');
        record_form.find('input[name="log_id"]').val('');
        record_form.find('.has-input').removeClass('has-input');
        record_form.find('.cpd-summary__journal-form-container').show();
        record_form.find('.cpd-summary__journal-form-file-link').hide();
        record_form.find('.cpd-summary__journal-form-label--file').html('Base record');
    },
    initCpdProgressCircle: function () {
        if (!$('.cpd-summary__category-progress').length) {
            return false;
        }
        $('.cpd-summary__category-progress').waypoint({
            handler: function (direction) {
                if (!$(this.element).data('animated')) {
                    main.kickOffCircleProgress(this.element);
                    $(this.element).data('animated', 'done');
                }
            },
            offset: 'bottom-in-view'
        });
    },
    kickOffCircleProgress: function (ele) {
        var transform_styles = ['-webkit-transform', '-ms-transform', 'transform'];
        var score_total = parseInt($(ele).data('total'));
        var score_value = parseInt($(ele).data('score'));
        if (score_value > score_total) {
            score_value = score_total;
        }
        var score = score_value / score_total;
        var rotation = Math.floor(score * 180);
        var fill_rotation = rotation;
        var fix_rotation = rotation * 2;
        for (var i in transform_styles) {
            $(ele).find('.circle .fill, .circle .mask.full').css(transform_styles[i], 'rotate(' + fill_rotation + 'deg)');
            $(ele).find('.circle .fill.fix').css(transform_styles[i], 'rotate(' + fix_rotation + 'deg)');
        }
    },
    initVideoHero: function () {
        if ($('.video-hero video').length) {
            $('.video-hero video').get(0).play();
        }
    },
    /**
     * Adds a has-input class to the input / textarea when the element
     * contains user input, this will help with styling material design
     * label
     */
    initInputValueDetection: function () {
        if (!$('input, textarea').length) {
            return false;
        }
        $('input, textarea').each(function () {
            $(this).change(function () {
                if ($(this).val() !== '') {
                    $(this).addClass('has-input');
                }
                else {
                    $(this).removeClass('has-input');
                }
            });
            $(this).trigger('change');
        });
    },
    /**
     * Init the search box, to make it show / hide based on if the
     * search input is focused upon or not.
     */
    initSearch: function () {
        if (!$('.header__search-input').length) {
            return false;
        }
        // Show search when input is focused
        $('.header__search-input').focus(function () {
            $('.header__search').addClass('active');
        });
        // Hide search when input loses focus - delay it by half a second
        // to allow the search submit to be pressed
        $('.header__search-input').blur(function () {
            setTimeout(function () {
                $('.header__search').removeClass('active');
            }, 300);
        });
        // Focus the search input when the search trigger is clicked upon
        $('.header__search-trigger').click(function () {
            $('.header__search-input').focus();
        });
        // Check empty submit to close the search
        $('.header__search-submit').click(function (e) {
            if ($('.header__search').hasClass('active')) {
                if (!$('.header__search-input').val()) {
                    e.preventDefault();
                    $('.header__search').removeClass('active');
                }
            }
            else {
                e.preventDefault();
                $('.header__search-input').focus();
            }
        });
    },
    /**
     * Init the Mobile menu, with its search function and mobile menu button
     */
    initMobileNav: function () {
        // if (!$('.navigation__mobile-nav').length){ return false; }
        //
        // // Initialize MMenu Plugin
        // var menu = $('.navigation__mobile-nav').first().mmenu({
        // 	position: 'right'
        // });
        //
        // // Open MMenu when mobile menu icon is clicked
        // $('.navigation__trigger-mobile-nav').first().on('click touchstart', function(event){
        // 	event.preventDefault();
        // 	menu.mmenu().trigger('open.mm');
        // });
    },
    /**
     * Initialisation all carousels
     */
    initCarousels: function () {
        main.attachImageOnloadInitCarousel('.sponsors__list', main.resizeSponsorCarousel);
    },
    attachImageOnloadInitCarousel: function (carousel, load_function) {
        if (!$(carousel).length) {
            return false;
        }
        var img = new Image();
        img.onload = load_function;
        img.src = $(carousel + ' img').first().attr('src');
        setTimeout(load_function, 2000);
    },
    resizeSponsorCarousel: function () {
        if (!$('.sponsors__list').length) {
            return false;
        }
        var items_to_display = null;
        var cart_items_to_display = null;
        switch (main.getViewport()) {
            case 'mobile':
                items_to_display = 2;
                break;
            case 'large-mobile':
                items_to_display = 3;
                break;
            case 'tablet':
                items_to_display = 4;
                break;
            case 'small-desktop':
                items_to_display = 5;
                break;
            case 'desktop':
                items_to_display = 6;
                break;
            case 'large-desktop':
            default:
                items_to_display = 6;
                break;
        }
        // Destroy and recreate the carousel, because just changing the config
        // on the fly does not seem to work very well no matter how many
        // reinitialise or updateSize functions you call.
        $('.sponsors__list').trigger('destroy');
        $('.sponsors__list').carouFredSel({
            auto: 7000,
            responsive: true,
            circular: true,
            infinite: true,
            align: 'center',
            items: {
                width: 270,
                height: 'variable',
                visible: items_to_display
            },
            next: '.sponsors__control--next',
            prev: '.sponsors__control--prev'
        });
    },
    getViewport: function () {
        if (Modernizr.mq('only all and (min-width: 1800px)')) {
            // Large Desktop
            return 'large-desktop';
        }
        else if (Modernizr.mq('only all and (min-width: 1200px)')) {
            // Desktop
            return 'desktop';
        }
        else if (Modernizr.mq('only all and (min-width: 992px)')) {
            // Small Desktop
            return 'small-desktop';
        }
        else if (Modernizr.mq('only all and (min-width: 768px)')) {
            // Tablet
            return 'tablet';
        }
        else if (Modernizr.mq('only all and (min-width: 460px)')) {
            // Large Mobile
            return 'large-mobile';
        }
        else {
            // Mobile
            return 'mobile';
        }
    },
    /**
     * Initialisation of any listeners required, such as window resize
     */
    current_viewport: '',
    initCarouselListeners: function () {
        main.current_viewport = main.getViewport();
        // Window Resize
        $(window).smartresize(function () {
            var new_viewport = main.getViewport();
            // Do these things only if the Viewport actually changes between specific sizes
            if (new_viewport != main.current_viewport) {
                main.current_viewport = main.getViewport();
                main.resizeSponsorCarousel();
            }
        });
    },
    initHeroCarousel: function () {
        if (!$('.hero-carousel').length) {
            return false;
        }
        $('.hero-carousel__item-list').carouFredSel({
            auto: 7000,
            responsive: true,
            align: 'center',
            items: {
                width: 1000,
                height: 'variable'
            },
            scroll: {
                items: 1,
                fx: 'crossfade'
            },
            pagination: '.hero-carousel__pagination'
        });
    },
    initContentCarousel: function () {
        if (!$('.content-carousel').length) {
            return false;
        }
        $('.content-carousel').carouFredSel({
            auto: 7000,
            responsive: true,
            // align: 'center',
            items: {
                width: 820,
                height: 'variable'
            },
            scroll: {
                items: 1,
                fx: 'crossfade'
            },
            pagination: '.content-carousel__pagination'
        });
    },
    /**
     * Initialises the ShareThis social icon plugin, in order for this to work
     * the html in the page must be in the following structure
     *  <div class="sharethis">
            <input type="hidden" class="share-url" value="http://www.absolute-url-to-the-shared-page.com">
            <input type="hidden" class="share-title" value="The title of the shared page">
            <input type="hidden" class="share-id" value="Unique-ID">
            <span id='st_linkedin_Unique-ID' class='linkedin'></span>
            <span id='st_twitter_Unique-ID' class='twitter'></span>
            <span id='st_facebook_Unique-ID' class='facebook'></span>
        </div>
     *
     * If the share-url is set the share will be for the specified url otherwise it will share the current page
     * If the share-title is set it will be the title text for the share, otherwise it will be the page title
     * The Unique-ID can be something like the node ID from iCMS or any arbitrary id, as long as it is unique on the
     * page, and it must be attached to the end of the ID of st_linkedin_ / st_twitter_ / st_facebook_
     */
    attachShareThis: function () {
        if (!$(".sharethis .share-id").length) {
            return false;
        }
        // init the ShareThis wedget
        stLight.options({
            publisher: "bc6eb617-d9cd-4fde-b6d7-704d262dbc52",
            popup: true,
            servicePopup: true,
            doNotHash: true,
            doNotCopy: true,
            hashAddressBar: false
        });
        // attach ShareThis buttons for each of the sharethis blocks on the page
        $(".sharethis").each(function () {
            // get Share url
            var share_url = window.location.href;
            if ($(this).find('.share-url').length) {
                share_url = $(this).find('.share-url').val();
            }
            // get Share title
            var share_title = document.getElementsByTagName("title")[0].innerHTML;
            if ($(this).find('.share-title').length) {
                share_title = $(this).find('.share-title').val();
            }
            // get Share unique id
            var share_id = $(this).find('.share-id').val();
            // attach linkedin, twitter and facebook share button
            var share_buttons = ["linkedin", "twitter", "facebook", "googleplus"];
            for (var i = 0; i < share_buttons.length; i++) {
                var button_id = 'st_' + share_buttons[i] + '_' + share_id;
                if ($(this).find('#' + button_id).length) {
                    stWidget.addEntry({
                        "service": share_buttons[i],
                        "element": document.getElementById(button_id),
                        "url": share_url,
                        "title": share_title,
                        "type": "chicklet" //type button,chicklet,custom,vcount,hcount
                    });
                }
            }
        });
    },
    /**
     * Scroll to specified DOM element
     * @param  Element element DOM or jQuery Element
     */
    scrollToElement: function (element, offset) {
        if (!(element instanceof jQuery)) {
            element = jQuery(element);
        }
        if (!offset) {
            offset = 0;
        }
        // var header_height = $('.header').first().outerHeight();
        $('html, body').animate({
            scrollTop: element.offset().top + offset
        }, 500);
    },
    initHappeningCarousel: function () {
        if (!$('.happening-carousel__item-list').length) {
            return false;
        }
        var slider = $('.happening-carousel__item-list').slick({
            arrows: false,
            dotsClass: 'slick-dots',
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            autoplay: true,
            speed: 3,
            autoplaySpeed: 5000
        });
    },
    //When user change the country, get the corresponding price
    attachChangeEventForCountry: function () {
        //only do this when the australia_price and other country price was setup
        if ($("#australia_price").val() != 0 && $("#other_country_price").val() != 0) {
            $("#country").on('change', function () {
                var country = $(this).val();
                if (country == 'Australia') {
                    $("#amount").val($("#australia_price").val());
                }
                else {
                    $("#amount").val($("#other_country_price").val());
                }
            });
        }
    }
};
// Make sure the browser has got the JSON strignify function
JSON.stringify = JSON.stringify || function (obj) {
    var t = typeof (obj);
    if (t != "object" || obj === null) {
        // simple data type
        if (t == "string")
            obj = '"' + obj + '"';
        return String(obj);
    }
    else {
        // recurse array or object
        var n, v, json = [], arr = (obj && obj.constructor == Array);
        for (n in obj) {
            v = obj[n];
            t = typeof (v);
            if (t == "string")
                v = '"' + v + '"';
            else if (t == "object" && v !== null)
                v = JSON.stringify(v);
            json.push((arr ? "" : '"' + n + '":') + String(v));
        }
        return (arr ? "[" : "{") + String(json) + (arr ? "]" : "}");
    }
};
