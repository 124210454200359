/// <reference path="references.ts"/>
var FindAPhysician;
FindAPhysician = {
    // Setup the News Listing
    init: function () {
        if (!$('.physicians__listing').length) {
            return false;
        }
        // Show no results message
        if (!$('.physicians__listing-item').length) {
            $('.message').show();
            return false;
        }
        // if (!$('.physicians__pager').length){ return false; }
        // FindAPhysician.determineFindAPhysicianPages();
        // FindAPhysician.showFindAPhysicianPage(1);
        // Attach google map
        FindAPhysician.attachGoogleMap();
        // Show right-hand side content for first item
        FindAPhysician.showMainContent($('.physicians__listing-item').first());
        // Add pagination listeners
        // $('.physicians__prev').click(FindAPhysician.showPrevFindAPhysicianPage);
        // $('.physicians__next').click(FindAPhysician.showNextFindAPhysicianPage);
        // Add item listeners
        FindAPhysician.initClickableListener();
    },
    initClickableListener: function () {
        // Click event on the whole item
        $('.physicians__listing-item-clickable').click(function (e) {
            var item = $(e.target).closest('.physicians__listing-item');
            if (item.length) {
                FindAPhysician.toggleFindAPhysicianItem(item);
            }
        });
        // Click event on the individual clinic address
        $('.physicians__listing-item-clinic--clickable').click(function (e) {
            e.preventDefault();
            e.stopPropagation();
            var item = $(e.target).closest('.physicians__listing-item');
            var marker_index = $(this).data('marker-index');
            if (item.length) {
                FindAPhysician.toggleFindAPhysicianItem(item, null, marker_index);
            }
        });
    },
    // Determine the pages for News Listing Items and add page-* classes
    determineFindAPhysicianPages: function () {
        var items_per_page = 5;
        var items = $('.physicians__listing-item');
        var total_pages = Math.ceil(items.length / items_per_page);
        $('.physicians').data('total-pages', total_pages);
        var cur_page = 1;
        var i = 0;
        items.each(function (n, elem) {
            $(this).addClass('page-' + cur_page);
            i++;
            if (i >= items_per_page) {
                i = 0;
                cur_page++;
            }
        });
    },
    // Show News Listing items from the specified page number
    showFindAPhysicianPage: function (page_num) {
        $('.physicians__listing-item').hide();
        $('.physicians__listing-item.page-' + page_num).show();
        $('.physicians').data('current-page', page_num);
        FindAPhysician.updateNewsPager();
    },
    showPrevFindAPhysicianPage: function () {
        var new_page = $('.physicians').data('current-page') - 1;
        if (new_page >= 1) {
            FindAPhysician.showFindAPhysicianPage(new_page);
        }
    },
    showNextFindAPhysicianPage: function () {
        var listing = $('.physicians');
        var total_pages = listing.data('total-pages');
        var new_page = listing.data('current-page') + 1;
        if (new_page <= total_pages) {
            FindAPhysician.showFindAPhysicianPage(new_page);
        }
    },
    updateNewsPager: function () {
        var listing = $('.physicians');
        var prev = $('.physicians__prev');
        var next = $('.physicians__next');
        if (listing.data('current-page') > 1) {
            prev.show();
        }
        else {
            prev.hide();
        }
        if (listing.data('current-page') < listing.data('total-pages')) {
            next.show();
        }
        else {
            next.hide();
        }
    },
    // Toggle show/hide item's content
    // @param Object item jQuery('.physicians__listing-item')
    // @param bool state Optional
    toggleFindAPhysicianItem: function (item, state, marker_index) {
        if (state === void 0) { state = null; }
        if (marker_index === void 0) { marker_index = null; }
        var is_active = item.hasClass('active');
        $('.physicians__listing-item.active').removeClass('active');
        item.addClass('active');
        FindAPhysician.showMainContent(item, marker_index);
        $('.content__main').show();
        var pos_y = item.find('.physicians__content').offset().top - $('.physicians').offset().top;
        $('.content__main').css('top', pos_y);
        $('.content__main').css('position', 'absolute');
    },
    // Fill in the main content area (right-hand side) with
    // details from the specified item
    // @param Object item jQuery('.physicians__listing-item')
    showMainContent: function (item, marker_index) {
        if (marker_index === void 0) { marker_index = null; }
        // Click the map marker programmatically
        if (!marker_index) {
            marker_index = item.data('marker-index');
        }
        google.maps.event.trigger(FindAPhysician.marker_array[marker_index], 'click');
        // Get data from item
        // var content = item.find('.physicians__content').html();
        // Create elements
        // var elem_content = $(document.createElement('div')).html(content);
        // Inject new content into main content area (right-hand side)
        // $('.content__main-inner').empty().append(
        // 	elem_content
        // );
        // Scroll to the top of the content
        setTimeout(function () {
            $('html, body').animate({
                scrollTop: $('.content__main-inner').offset().top
            }, 500);
        }, 500);
    },
    getResultCount: function () {
        var result_count = $('.physicians__listing').attr('data-count');
        return result_count;
    },
    showResultsNotFoundMessage: function () {
        if (FindAPhysician.getResultCount() < 1) {
            $('#physicians__map > p').attr('style', 'display:block;');
        }
        console.log("nope");
    },
    map: null,
    marker_array: [],
    active_infowindow: null,
    attachGoogleMap: function () {
        if (!$('.physicians__map-detail').length) {
            return false;
        }
        if (FindAPhysician.getResultCount() < 1) {
            FindAPhysician.showResultsNotFoundMessage();
            return false;
        }
        var mapOptions = {
            center: new google.maps.LatLng(-27.393717, 148.315430),
            zoom: 4,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            streetViewControl: false,
            panControl: false,
            rotateControl: false,
            mapTypeControl: false,
            draggable: true,
            scrollwheel: false,
            styles: [
                {
                    "featureType": "landscape",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 65
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 51
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 30
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 40
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "administrative.province",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "lightness": -25
                        },
                        {
                            "saturation": -100
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "hue": "#ffff00"
                        },
                        {
                            "lightness": -25
                        },
                        {
                            "saturation": -97
                        }
                    ]
                }
            ]
        };
        FindAPhysician.map = new google.maps.Map(document.getElementById("physicians__map"), mapOptions);
        // an array of all the map markers. We need this because we will need to reference them and change the image if an item is clicked
        $('.physicians__map-detail').each(function () {
            var address_lat = $(this).data('lat');
            var address_lng = $(this).data('lng');
            var name = $(this).data('name');
            var phone = $(this).data('phone') ? '<span><a href="tel:' + $(this).data('email') + '">' + $(this).data('phone') + '</a></span><br>' : '';
            var email = $(this).data('email') ? '<span><a href="mailto:' + $(this).data('email') + '">' + $(this).data('email') + '</a></span><br>' : '';
            var website = $(this).data('website') ? '<span><a href="http://' + $(this).data('website') + '">' + $(this).data('website') + '</a></span><br>' : '';
            var hours = $(this).data('hours') ? '<span>' + $(this).data('hours') + '</span><br>' : '';
            var address = $(this).data('address') ? '<span><a href="http://maps.apple.com/?q=' + address_lat + ',' + address_lng + '">' + $(this).data('address') + '</a></span><br>' : '';
            var interest = $(this).data('interest') ? '<span>Special interests: ' + $(this).data('interest') + '</span><br>' : '';
            var marker = new google.maps.Marker({
                map: FindAPhysician.map,
                position: new google.maps.LatLng(address_lat, address_lng),
                icon: '/img/icons/marker-yellow.png'
            });
            // Add the popup info window
            marker.info = new google.maps.InfoWindow({
                content: '<h4>' + name + '</h4>' + phone + email + website + hours + address + interest
            });
            google.maps.event.addListener(marker, 'click', function () {
                FindAPhysician.map.setZoom(15);
                FindAPhysician.map.panTo(marker.position);
                if (FindAPhysician.active_infowindow) {
                    FindAPhysician.active_infowindow.close();
                }
                marker.info.open(FindAPhysician.map, marker);
                FindAPhysician.active_infowindow = marker.info;
            });
            FindAPhysician.marker_array.push(marker);
        });
    }
};
