/// <reference path="references.ts"/>
var NewsListing;
NewsListing = {
    // Setup the News Listing
    init: function () {
        if (!$('.listing-news').length) {
            return false;
        }
        // if (!$('.news__pager').length){ return false; }
        // NewsListing.determineNewsListingPages();
        // NewsListing.showNewsListingPage(1);
        // Show right-hand side content for first item
        NewsListing.showMainContent($('#news .listing-news__item').first());
        // Add pagination listeners
        // $('.news__prev').click(NewsListing.showPrevNewsListingPage);
        // $('.news__next').click(NewsListing.showNextNewsListingPage);
        // Add item listeners
        $('.listing-news__item-clickable').click(function (evnt) {
            var item = $(evnt.target).closest('.listing-news__item');
            // If the calendar exists, hide it
            if ($('#calendar_wrapper').length) {
                $('#calendar_wrapper').hide();
                $('#show_calendar').show();
            }
            if (item.length) {
                NewsListing.toggleNewsListingItem(item);
            }
        });
    },
    // Determine the pages for News Listing Items and add page-* classes
    determineNewsListingPages: function () {
        var items_per_page = 5;
        var items = $('.listing-news__item');
        var total_pages = Math.ceil(items.length / items_per_page);
        $('.listing-news').data('total-pages', total_pages);
        var cur_page = 1;
        var i = 0;
        items.each(function (n, elem) {
            $(this).addClass('page-' + cur_page);
            i++;
            if (i >= items_per_page) {
                i = 0;
                cur_page++;
            }
        });
    },
    // Show News Listing items from the specified page number
    showNewsListingPage: function (page_num) {
        $('.listing-news__item').hide();
        $('.listing-news__item.page-' + page_num).show();
        $('.listing-news').data('current-page', page_num);
        NewsListing.updateNewsPager();
    },
    showPrevNewsListingPage: function () {
        var new_page = $('.listing-news').data('current-page') - 1;
        if (new_page >= 1) {
            NewsListing.showNewsListingPage(new_page);
        }
    },
    showNextNewsListingPage: function () {
        var listing = $('.listing-news');
        var total_pages = listing.data('total-pages');
        var new_page = listing.data('current-page') + 1;
        if (new_page <= total_pages) {
            NewsListing.showNewsListingPage(new_page);
        }
    },
    updateNewsPager: function () {
        var listing = $('.listing-news');
        var prev = $('.news__prev');
        var next = $('.news__next');
        if (listing.data('current-page') > 1) {
            prev.show();
        }
        else {
            prev.hide();
        }
        if (listing.data('current-page') < listing.data('total-pages')) {
            next.show();
        }
        else {
            next.hide();
        }
    },
    // Toggle show/hide item's content
    // @param Object item jQuery('.listing-news__item')
    // @param bool state Optional
    toggleNewsListingItem: function (item, state) {
        if (state === void 0) { state = null; }
        var is_active = item.hasClass('active');
        if (!is_active && state !== false) {
            $('.listing-news__item.active').removeClass('active');
            item.addClass('active');
            NewsListing.showMainContent(item);
        }
        if (is_active && state !== true) {
            $('.listing-news__item.active').removeClass('active');
        }
    },
    // Fill in the main content area (right-hand side) with
    // details from the specified item
    // @param Object item jQuery('.listing-news__item')
    showMainContent: function (item) {
        // Get data from item
        var title = item.find('.listing-news__item-title').data('title');
        if (!title) {
            title = item.find('.listing-news__item-title').text();
        }
        var date = item.find('.listing-news__item-date').text();
        var content = item.find('.listing-news__content').html();
        // Create elements
        var elem_title = $(document.createElement('h2')).addClass('content__main-title').html(title);
        var elem_date = $(document.createElement('p')).addClass('content__sub-text').html(date);
        var elem_content = $(document.createElement('div')).html(content);
        // Inject new content into main content area (right-hand side)
        $('.content__main-inner').empty().append(elem_title, elem_date, elem_content);
        // Scroll to the top of the content			
        $('html, body').animate({
            scrollTop: $('.content__main-inner').offset().top
        }, 500);
    },
};
